<template>
  <div class="rule" v-if="userInfo != null">
    <!--  标签页-->
    <el-tabs v-model="activeName"  class="el-nav" @tab-click="handleClick">
      <el-tab-pane label="用户管理" name="user"></el-tab-pane>

      <!--      新增课程-->
      <el-tab-pane label="用户详情" name="show">
        <el-form label-position="right" label-width="120px" class="listForm">

          <el-form-item label="昵称">
            <div class="basic-text" >{{userInfo.user && userInfo.user.nickname}}</div>
          </el-form-item>

          <el-form-item label="头像">
            <div class="block"><el-avatar shape="square" :size="60" :src="userInfo.user.avatar"></el-avatar></div>
          </el-form-item>
          <el-form-item label="等级">
            <div class="basic-text">{{userInfo.user && userInfo.account.level}}级</div>
          </el-form-item>
          <el-form-item label="邀请人">
            <div class="basic-text" >{{userInfo.current_circle.inviter ? userInfo.current_circle.inviter.nickname : ''}}</div>
          </el-form-item>

          <el-form-item label="身份">
            <div class="basic-text" v-if="userInfo.user && userInfo.current_circle.type === 1">普通用户</div>
            <div class="basic-text" v-else-if="userInfo.user && userInfo.current_circle.type === 2">管理员</div>
            <div class="basic-text" v-else-if="userInfo.user && userInfo.current_circle.type === 3">超级管理员</div>
          </el-form-item>

          <el-form-item label="直播权限">
            <div class="basic-text">{{userInfo.is_presenter ? '有' : '无'}}</div>
          </el-form-item>
          <el-form-item label="手机">
            <div class="basic-text">{{userInfo.user && userInfo.user.mobile}}</div>
          </el-form-item>
          <el-form-item label="邮箱">
            <div class="basic-text" >{{userInfo.user && userInfo.user.email}}</div>
          </el-form-item>

          <el-form-item label="性别">
            <div class="basic-text">{{userInfo.gender === 1 ? '男':'女'}}</div>
          </el-form-item>

          <el-form-item label="生日">
            <div class="basic-text">{{userInfo.birthday}}</div>
          </el-form-item>
<!--          <el-form-item label="职位">-->
<!--            <div class="basic-text">{{userInfo.job_title}}</div>-->
<!--          </el-form-item>-->
          <el-form-item label="地区">
            <div class="basic-text" >{{userInfo.province}}-{{userInfo.city}}-{{userInfo.area}}</div>
          </el-form-item>

          <el-form-item label="签名">
            <div class="basic-text">{{userInfo.user && userInfo.user.signature}}</div>
          </el-form-item>

          <el-form-item label="简介">
            <div class="basic-text">{{userInfo.user && userInfo.user.about}}</div>
          </el-form-item>
          <el-form-item label="登录信息">
            <div class="basic-text">登录次数:{{userInfo.user && userInfo.user.login_count}}次</div>
            <div class="basic-text">最后登录时间：{{userInfo.user && userInfo.user.last_login_at}}，最后登录IP：{{userInfo.user && userInfo.user.last_login_ip}}，最后登录城市：{{userInfo.user && userInfo.user.last_login_city}}</div>
          </el-form-item>
          <el-form-item label="注册时间">
            <div class="basic-text" >{{userInfo.created_at}}</div>
          </el-form-item>

          <el-form-item label="Ta的课程">
            <div class="basic-text see-text" @click="showCourse">查看课程</div>
          </el-form-item>

        </el-form>
        <div class="form-operation">
          <el-button class="form-operation-item" @click="goBack" round>返回</el-button>
        </div>
      </el-tab-pane>
    </el-tabs>
    <!--    新增修改弹框-->
    <el-dialog
        class="dialog"
        title="Ta的课程"
        :visible.sync="isDialogVisible"
        top="20vh"
        width="840px"
        :destroy-on-close="true"
    >

      <div class="total space-between">
        <div class="total-left">共有 <span class="total-left-text">{{userCourse.meta ? userCourse.meta.total : 0}}</span> 门课程</div>
      </div>

      <!--        查看课程-->
      <el-table
          class="table"
          ref="multipleTable"
          :data="userCourse.data"
          tooltip-effect="dark"
          style="width: 100%"
          :header-cell-style="{background:'#F6F8F9',color: '#1C1F21',fontWeight:'400'}"
          border>

        <el-table-column
            prop="id"
            label="编号"
            width="72">

        </el-table-column>

        <el-table-column
            label="名称"
        >
          <template slot-scope="scope">
             <div class="course-tit">{{scope.row.course.title}}</div>
             <div class="course-tag">
               <span>标签：</span>
               <span v-for="(item, index) in scope.row.course.tags" :key="index">{{item.name}} {{index != scope.row.course.tags.length -1 ? '、' :''}}</span>
             </div>
          </template>
        </el-table-column>
<!--        <el-table-column
            label="标签"
            width="80">
          <template slot-scope="scope">

          </template>
        </el-table-column>-->
        <el-table-column
            prop="last_learn_at"
            label="最后学习时间"
            width="160">
        </el-table-column>
        <el-table-column
            prop="created_at"
            label="创建时间"
            width="160"
        >
        </el-table-column>

      </el-table>
      <el-pagination
          v-if="userCourse.meta && userCourse.meta.last_page > 1"
          class="page"
          background
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-size="pageSize"
          layout=" prev, pager, next,total, jumper"
          :total="userCourse.meta ? userCourse.meta.total : 0">
      </el-pagination>

      <!--      <span slot="footer" class="dialog-footer form-operation">-->
      <!--        <el-button class="form-operation-item" round @click="isDialogVisible = false">取 消</el-button>-->
      <!--        <el-button class="form-operation-item" round type="primary" @click="tagPut">确 定</el-button>-->
      <!--      </span>-->
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "Show",
  data() {
    return{
      activeName: 'show',
      uuid: '',
      userInfo: null, // 用户详情
      tableData: [],
      isDialogVisible: false, // 课程弹框
      userCourse: {}, // 用户课程列表
      page: 1,
      pageSize: 20,
    }
  },
  created() {
    if(this.$route.params.uuid) {
      this.uuid= this.$route.params.uuid
      this.getUser()
    }
  },
  methods: {
    // 获取用户信息
    getUser(){
      let url= this.$api.Users + '/' + this.uuid;
      this.$http.get(url, true).then(res =>{
        if(res.data.success) {
          this.userInfo= res.data.data
        }
      })
    },
    // 查看课程展示
    showCourse() {
      this.getUserCourses();
      this.isDialogVisible= true
    },
    // 获取用户课程
    getUserCourses() {
      let url= this.$api.userCourses+ '?uuid=' + this.uuid;
      this.$http.get(url, true).then(res =>{
        if(res.data.success) {
          this.userCourse= res.data.data
        }
      })
    },
    // 页数
    handleCurrentChange(val) {

    },
    // tab切换想像
    handleClick(tab, event) {
      if(tab.name === 'user') {
        this.$router.push('/user/user')
      }
    },
    // 返回上一页
    goBack() {
      window.history.go(-1)
    }
  }
}
</script>

<style lang="less" scoped>
@import "~assets/less/user/index.less";
.course-tit{
  font-size: 14px;
  font-weight: 400;
  color: #1c1f21;
}
.course-tag{
  line-height: 1.3;
  font-size: 12px;
  font-weight: 400;
  color: #93999f;
}
</style>
